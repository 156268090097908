.ContainerHome {
    overflow: hidden;
    width: 100vw;
    height: 83vh;
    background-color: #F0F0F0;
    align-items:flex-start;
    padding-inline: 20px;
    padding-block: 20px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.ContainerTableNav2{
    width: 100%;
    min-height: 190px;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 10px;
    align-items: center;
    padding-inline: 10px;
    display: flex;
    padding-block: 10px;
    justify-content: space-between;
    background-color: #1A2B52;
}
.ContainerTableNav2 button {
    height: 50px;
    background-color: #08dd61;
}

.ContainerTable2{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding-inline: 10px;
    padding-block: 20px;
    overflow: auto;
    background-color: #FFF;
}