.ContainerHome {
    overflow: hidden;
    width: 100vw;
    height: 83vh;
    background-color: #F0F0F0;
    align-items:flex-start;
    padding-inline: 20px;
    padding-block: 20px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.ContainerTableNav{
    width: 100%;
    height: 80px;
    border-radius: 10px;
    margin-bottom: 10px;
    align-items: center;
    padding-inline: 10px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    background-color: #fff;
}
.ContainerTableNav button {
    height: 60px;
    background-color: #1A2B52;
}

.ContainerTable{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: auto;
    background-color: #fff;
}